"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQueryParamsAsJson = void 0;
var _react = require("react");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
/**
 * Custom React hook that parses the current URL query parameters into a JSON-like object.
 *
 * - Handles multiple values for the same key by converting them into arrays.
 * - Allows ignoring specific keys during parsing via the `keysToIgnore` parameter.
 * - Provides a function to serialize the parsed query object back into a valid query string.
 * - Also returns a JSON string version of the query object for easy storage/transfer.
 *
 * @param {string[]} [keysToIgnore=[]] - Optional array of keys to exclude from the returned query object.
 *                                       These keys will be ignored during parsing and will not be included in the output.
 *
 * @returns {{
 *   capturedUrlParams: Record<string, string | string[]>,
 *   capturedParamsToString: () => string,
 *   rawUrlParams: URLSearchParams
 * }} An object containing:
 *   - `capturedUrlParams`: An object where each query parameter is a key. If a parameter appears multiple times, its value is an array.
 *   - `toQueryString`: A function that converts the current `capturedUrlParams` back into a query string.
 *   - `rawUrlParams`: raw url params search object.
 *
 * @example
 * // URL: https://example.com/?utm_source=google&tag=react&tag=javascript&ref=123
 *
 * const { capturedUrlParams, capturedParamsToString, rawUrlParams } = useQueryParamsAsJson(['ref']);
 *
 * console.log(capturedUrlParams);
 * // { utm_source: "google", tag: ["react", "javascript"] }
 *
 * console.log(capturedParamsToString());
 * // '{"utm_source":"google","tag":["react","javascript"]}'
 *
 * console.log(rawUrlParams);
 * // new URLSearchParams(window.location.search)
 */
var useQueryParamsAsJson = exports.useQueryParamsAsJson = function useQueryParamsAsJson() {
  var keysToIgnore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var search = window.location.search;
  var params = (0, _react.useMemo)(function () {
    return new URLSearchParams(search);
  }, [search]);
  var queryObject = {};
  var _iterator = _createForOfIteratorHelper(params.entries()),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
        key = _step$value[0],
        value = _step$value[1];
      // Skip ignored keys
      if (keysToIgnore.includes(key)) {
        continue;
      }

      // Handle duplicate keys by converting to array
      if (queryObject[key]) {
        queryObject[key] = Array.isArray(queryObject[key]) ? [].concat(_toConsumableArray(queryObject[key]), [value]) : [queryObject[key], value];
      } else {
        queryObject[key] = value;
      }
    }

    // Function to convert the current queryObject back into a query string
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var toQueryString = (0, _react.useCallback)(function () {
    var params = new URLSearchParams();
    Object.entries(queryObject).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      if (Array.isArray(value)) {
        value.forEach(function (v) {
          return params.append(key, v);
        });
      } else if (value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return params.toString(); // Example: "key=value&key2=value2"
  }, [queryObject]);
  var rawUrlParams = params;

  // Return as a JSON object
  return {
    capturedUrlParams: queryObject,
    capturedParamsToString: toQueryString,
    rawUrlParams: rawUrlParams
  };
};