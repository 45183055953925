"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boostTiers = exports.boostProductTierYearlyConfig_v3 = exports.boostProductTierYearlyConfig = exports.boostProductTierMonthlyConfig_v3 = exports.boostProductTierMonthlyConfig = exports.boostProductTierConfig_v3 = exports.boostProductTierConfig = exports.BOOST_TERMS_AND_CONDITIONS_URL = exports.BOOST_SUBSCRIPTION_FEATURE_PERKS = exports.BOOST_PRICING_DEFAULT_TERM = void 0;
var _types = require("../../types");
var _features = require("./features");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var boostTiers = exports.boostTiers = {
  BASIC: "BASIC",
  PLUS: "PLUS",
  PREMIUM: "PREMIUM",
  VIP: "VIP"
};
var BOOST_SUBSCRIPTION_FEATURE_PERKS = exports.BOOST_SUBSCRIPTION_FEATURE_PERKS = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _features.BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE, "Credit line"), _features.BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL, "No hard credit pulls"), _features.BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING, "Credit score tracking"), _features.BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST, "Instant credit boost"), _features.BOOST_SUBSCRIPTION_FEATURES.RENT_REPORTING, "Rent reporting"), _features.BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE, "Dispute fraud"), _features.BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT, "AI Credit coach"), _features.BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT, "Detailed credit monitoring");
var boostProductTierMonthlyConfig = exports.boostProductTierMonthlyConfig = {
  BASIC: {
    title: "🛰️ Basic Journey",
    name: "Basic",
    icon: "🛰️",
    price: 14.99,
    originalPrice: 19.99,
    promoActive: false,
    promoDiscountPercentage: 0,
    billingPeriod: "Per month",
    maxLine: 1000,
    minLine: 500,
    lookbackPeriod: "2 months",
    perks: ["2 months of instant credit history reported.", "2 months of past bills added to your account.", "Up to $1000/per month reported as credit.*"],
    features: _features.BASIC_PLAN_FEATURES,
    featuresMobile: _features.BASIC_PLAN_FEATURES_MOBILE
  },
  PLUS: {
    title: "🌙 Plus Journey",
    name: "Plus",
    icon: "🌙",
    price: 29.99,
    originalPrice: 69.99,
    promoActive: false,
    promoDiscountPercentage: 0,
    billingPeriod: "Per month",
    maxLine: 5000,
    minLine: 1000,
    lookbackPeriod: "6 months",
    perks: ["6 months of instant credit history reported.", "6 months of past bills added to your account.", "Up to $5000/per month reported as credit.*"],
    features: _features.PLUS_PLAN_FEATURES,
    featuresMobile: _features.PLUS_PLAN_FEATURES_MOBILE
  },
  PREMIUM: {
    title: "🪐 Premium Journey",
    name: "Premium",
    icon: "🪐",
    price: 69.99,
    originalPrice: 99.99,
    promoActive: false,
    promoDiscountPercentage: 0,
    billingPeriod: "Per month",
    maxLine: 20000,
    minLine: 5000,
    lookbackPeriod: "12 months",
    label: "Popular",
    perks: ["12 months of instant credit history reported.", "12 months of past bills added to your account.", "Up to $20,000/per month reported as credit.*"],
    features: _features.PREMIUM_PLAN_FEATURES,
    featuresMobile: _features.PREMIUM_PLAN_FEATURES_MOBILE
  },
  VIP: {
    title: "☀️ VIP Journey",
    name: "VIP",
    buttonText: "VIP 🚀",
    icon: "☀️",
    price: 129.99,
    originalPrice: 274.99,
    promoActive: false,
    promoDiscountPercentage: 0,
    billingPeriod: "Per month",
    maxLine: 30000,
    minLine: 10000,
    lookbackPeriod: "24 months",
    label: "Best Value",
    perks: ["24 months of instant credit history reported.", "24 months of past bills added to your account.", "Up to $30,000/per month reported as credit.*"],
    features: _features.VIP_PLAN_FEATURES,
    featuresMobile: _features.VIP_PLAN_FEATURES_MOBILE,
    featuresSelected: _features.VIP_PLAN_FEATURES_SELECTED
  }
};
var boostProductTierYearlyConfig = exports.boostProductTierYearlyConfig = {
  BASIC: {
    title: "🛰️ Basic Journey",
    name: "Basic",
    icon: "🛰️",
    price: 99.99,
    originalPrice: 19.99 * 12,
    promoActive: false,
    promoDiscountPercentage: 70,
    billingPeriod: "Per year",
    maxLine: 1000,
    minLine: 500,
    lookbackPeriod: "2 months",
    perks: ["2 months of instant credit history reported.", "2 months of past bills added to your account.", "Up to $1000/per month reported as credit.*"],
    features: _features.BASIC_PLAN_FEATURES,
    featuresMobile: _features.BASIC_PLAN_FEATURES_MOBILE
  },
  PLUS: {
    title: "🌙 Plus Journey",
    name: "Plus",
    icon: "🌙",
    price: 249.99,
    originalPrice: 69.99 * 12,
    promoActive: false,
    promoDiscountPercentage: 70,
    billingPeriod: "Per year",
    maxLine: 5000,
    minLine: 1000,
    lookbackPeriod: "6 months",
    perks: ["6 months of instant credit history reported.", "6 months of past bills added to your account.", "Up to $5000/per month reported as credit.*"],
    features: _features.PLUS_PLAN_FEATURES,
    featuresMobile: _features.PLUS_PLAN_FEATURES_MOBILE
  },
  PREMIUM: {
    title: "🪐 Premium Journey",
    name: "Premium",
    icon: "🪐",
    price: 399.99,
    originalPrice: 99.99 * 12,
    promoActive: false,
    promoDiscountPercentage: 70,
    billingPeriod: "Per year",
    maxLine: 20000,
    minLine: 5000,
    lookbackPeriod: "12 months",
    label: "Popular",
    perks: ["12 months of instant credit history reported.", "12 months of past bills added to your account.", "Up to $20,000/per month reported as credit.*"],
    features: _features.PREMIUM_PLAN_FEATURES,
    featuresMobile: _features.PREMIUM_PLAN_FEATURES_MOBILE
  },
  VIP: {
    title: "☀️ VIP Journey",
    name: "VIP",
    buttonText: "VIP 🚀",
    icon: "☀️",
    price: 999.99,
    originalPrice: 274.99 * 12,
    promoActive: false,
    promoDiscountPercentage: 70,
    billingPeriod: "Per year",
    maxLine: 30000,
    minLine: 10000,
    lookbackPeriod: "24 months",
    label: "Best Value",
    perks: ["24 months of instant credit history reported.", "24 months of past bills added to your account.", "Up to $30,000/per month reported as credit.*"],
    features: _features.VIP_PLAN_FEATURES,
    featuresMobile: _features.VIP_PLAN_FEATURES_MOBILE,
    featuresSelected: _features.VIP_PLAN_FEATURES_SELECTED
  }
};
var boostProductTierConfig = exports.boostProductTierConfig = {
  monthly: boostProductTierMonthlyConfig,
  yearly: boostProductTierYearlyConfig
};

// @ts-ignore
var boostProductTierMonthlyConfig_v3 = exports.boostProductTierMonthlyConfig_v3 = _defineProperty(_defineProperty(_defineProperty({}, _types.BoostTier.BASIC, _objectSpread(_objectSpread({}, boostProductTierMonthlyConfig.BASIC), {}, {
  features: _features.BASIC_PLAN_FEATURES_v3,
  featuresMobile: _features.BASIC_PLAN_FEATURES_MOBILE_v3
})), _types.BoostTier.PREMIUM, _objectSpread(_objectSpread({}, boostProductTierMonthlyConfig.PREMIUM), {}, {
  features: _features.PREMIUM_PLAN_FEATURES_v3,
  featuresMobile: _features.PREMIUM_PLAN_FEATURES_MOBILE_v3
})), _types.BoostTier.VIP, _objectSpread(_objectSpread({}, boostProductTierMonthlyConfig.VIP), {}, {
  features: _features.VIP_PLAN_FEATURES_v3,
  featuresMobile: _features.VIP_PLAN_FEATURES_MOBILE_v3
}));

// @ts-ignore
var boostProductTierYearlyConfig_v3 = exports.boostProductTierYearlyConfig_v3 = _defineProperty(_defineProperty(_defineProperty({}, _types.BoostTier.BASIC, _objectSpread(_objectSpread({}, boostProductTierYearlyConfig.BASIC), {}, {
  features: _features.BASIC_PLAN_FEATURES_v3,
  featuresMobile: _features.BASIC_PLAN_FEATURES_MOBILE_v3
})), _types.BoostTier.PREMIUM, _objectSpread(_objectSpread({}, boostProductTierYearlyConfig.PREMIUM), {}, {
  features: _features.PREMIUM_PLAN_FEATURES_v3,
  featuresMobile: _features.PREMIUM_PLAN_FEATURES_MOBILE_v3
})), _types.BoostTier.VIP, _objectSpread(_objectSpread({}, boostProductTierYearlyConfig.VIP), {}, {
  features: _features.VIP_PLAN_FEATURES_v3,
  featuresMobile: _features.VIP_PLAN_FEATURES_MOBILE_v3
}));
var boostProductTierConfig_v3 = exports.boostProductTierConfig_v3 = _defineProperty(_defineProperty({}, _types.BoostTerm.MONTHLY, boostProductTierMonthlyConfig_v3), _types.BoostTerm.YEARLY, boostProductTierYearlyConfig_v3);
var BOOST_TERMS_AND_CONDITIONS_URL = exports.BOOST_TERMS_AND_CONDITIONS_URL = "https://tomo-public-docs.s3.us-west-1.amazonaws.com/Terms+and+Conditions.pdf";
var BOOST_PRICING_DEFAULT_TERM = exports.BOOST_PRICING_DEFAULT_TERM = _types.BoostTerm.YEARLY;