"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIP_PLAN_FEATURES_v3 = exports.VIP_PLAN_FEATURES_SELECTED = exports.VIP_PLAN_FEATURES_MOBILE_v3 = exports.VIP_PLAN_FEATURES_MOBILE = exports.VIP_PLAN_FEATURES = exports.PREMIUM_PLAN_FEATURES_v3 = exports.PREMIUM_PLAN_FEATURES_MOBILE_v3 = exports.PREMIUM_PLAN_FEATURES_MOBILE = exports.PREMIUM_PLAN_FEATURES = exports.PLUS_PLAN_FEATURES_MOBILE = exports.PLUS_PLAN_FEATURES = exports.BOOST_SUBSCRIPTION_FEATURES = exports.BASIC_PLAN_FEATURES_v3 = exports.BASIC_PLAN_FEATURES_MOBILE_v3 = exports.BASIC_PLAN_FEATURES_MOBILE = exports.BASIC_PLAN_FEATURES = void 0;
var BOOST_SUBSCRIPTION_FEATURES = exports.BOOST_SUBSCRIPTION_FEATURES = {
  CREDIT_LINE: "CREDIT_LINE",
  NO_HARD_PULL: "NO_HARD_PULL",
  CREDIT_MONITORING: "CREDIT_MONITORING",
  CREDIT_BOOST: "CREDIT_BOOST",
  RENT_REPORTING: "RENT_REPORTING",
  BUSINESS_CREDIT_CARD: "BUSINESS_CREDIT_CARD",
  REPORT_DISPUTE: "REPORT_DISPUTE",
  AI_FINANCIAL_ASSISTANT: "AI_FINANCIAL_ASSISTANT",
  CREDIT_REPORT: "CREDIT_REPORT",
  BILL_TRACKING: "BILL_TRACKING",
  ALL_BUREAU_SUPPORT: "ALL_BUREAU_SUPPORT",
  PRIORITY_SUPPORT: "PRIORITY_SUPPORT",
  CREDIT_CONSULTATION: "CREDIT_CONSULTATION"
};
var BASIC_PLAN_FEATURES = exports.BASIC_PLAN_FEATURES = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontWeight: 400
    }
  }, {
    text: "$1,000",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING
}];
var BASIC_PLAN_FEATURES_MOBILE = exports.BASIC_PLAN_FEATURES_MOBILE = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to "
  }, {
    text: "$1,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit score tracking"
  }]
}];
var PREMIUM_PLAN_FEATURES = exports.PREMIUM_PLAN_FEATURES = [{
  title: BOOST_SUBSCRIPTION_FEATURES.BUSINESS_CREDIT_CARD
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontWeight: 400
    }
  }, {
    text: "$10,000",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST
}];
var PREMIUM_PLAN_FEATURES_MOBILE = exports.PREMIUM_PLAN_FEATURES_MOBILE = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontWeight: 400
    }
  }, {
    text: "$10,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line",
    style: {
      fontWeight: 400
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit score tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST,
  description: [{
    text: "Instant credit boost"
  }]
}];

// TODO check features
var PLUS_PLAN_FEATURES = exports.PLUS_PLAN_FEATURES = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontSize: 24
    }
  }, {
    text: "$5,000",
    style: {
      fontWeight: 700,
      fontSize: 24
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: []
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: []
}];
var PLUS_PLAN_FEATURES_MOBILE = exports.PLUS_PLAN_FEATURES_MOBILE = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to "
  }, {
    text: "$5,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var VIP_PLAN_FEATURES = exports.VIP_PLAN_FEATURES = [{
  title: BOOST_SUBSCRIPTION_FEATURES.BUSINESS_CREDIT_CARD
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontWeight: 400
    }
  }, {
    text: "$30,000",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT
}];
var VIP_PLAN_FEATURES_MOBILE = exports.VIP_PLAN_FEATURES_MOBILE = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to ",
    style: {
      fontWeight: 400
    }
  }, {
    text: "$30,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit score tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST,
  description: [{
    text: "Instant credit boost"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute fraud"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT,
  description: [{
    text: "AI financial coach"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "Detailed credit monitoring"
  }]
}];
var VIP_PLAN_FEATURES_SELECTED = exports.VIP_PLAN_FEATURES_SELECTED = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "Premium benefits",
    style: {
      fontWeight: 700,
      color: "#7249F5"
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "Detailed credit monitoring",
    style: {
      fontWeight: 700,
      color: "#7249F5"
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute fraud",
    style: {
      fontWeight: 700,
      color: "#7249F5"
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT,
  description: [{
    text: "AI financial coach",
    style: {
      fontWeight: 700,
      color: "#7249F5"
    }
  }]
}];
var BASIC_PLAN_FEATURES_v3 = exports.BASIC_PLAN_FEATURES_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to $1,000 credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "2 months of bill tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var BASIC_PLAN_FEATURES_MOBILE_v3 = exports.BASIC_PLAN_FEATURES_MOBILE_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to "
  }, {
    text: "$1,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "2 months",
    style: {
      fontWeight: 700
    }
  }, {
    text: "of bill tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var PREMIUM_PLAN_FEATURES_v3 = exports.PREMIUM_PLAN_FEATURES_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to $10,000 credit line",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "6 months of bill tracking",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "In-depth credit report ($50 value)"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute negative remarks"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var PREMIUM_PLAN_FEATURES_MOBILE_v3 = exports.PREMIUM_PLAN_FEATURES_MOBILE_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to "
  }, {
    text: "$10,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "6 months",
    style: {
      fontWeight: 700
    }
  }, {
    text: "of bill tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "In-depth credit report ($50 value)"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute negative remarks"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var VIP_PLAN_FEATURES_v3 = exports.VIP_PLAN_FEATURES_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.BUSINESS_CREDIT_CARD,
  description: [{
    text: "New",
    style: {
      color: "#000000",
      padding: "4px 8px 4px 8px",
      borderRadius: 2,
      background: "rgba(54, 236, 60, 1)",
      marginRight: 8,
      fontWeight: 600
    }
  }, {
    text: "Business Credit Card"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to $30,000 credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "24 months of bill tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_CONSULTATION,
  description: [{
    text: "Credit consultation"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST,
  description: [{
    text: "Instant credit boost"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT,
  description: [{
    text: "AI financial assistant"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.PRIORITY_SUPPORT,
  description: [{
    text: "Priority support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "In-depth credit report ($50 value)"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute negative remarks"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];
var VIP_PLAN_FEATURES_MOBILE_v3 = exports.VIP_PLAN_FEATURES_MOBILE_v3 = [{
  title: BOOST_SUBSCRIPTION_FEATURES.BUSINESS_CREDIT_CARD,
  description: [{
    text: "New",
    style: {
      color: "#000000",
      padding: "4px 8px 4px 8px",
      borderRadius: 2,
      background: "rgba(54, 236, 60, 1)",
      marginRight: 8,
      fontWeight: 600
    }
  }, {
    text: "Business Credit Card",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_LINE,
  description: [{
    text: "up to "
  }, {
    text: "$30,000",
    style: {
      fontWeight: 700
    }
  }, {
    text: " credit line"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.BILL_TRACKING,
  description: [{
    text: "24 months",
    style: {
      fontWeight: 700
    }
  }, {
    text: "of bill tracking"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_CONSULTATION,
  description: [{
    text: "Credit consultation"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_BOOST,
  description: [{
    text: "Instant credit boost",
    style: {
      fontWeight: 700
    }
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.AI_FINANCIAL_ASSISTANT,
  description: [{
    text: "AI financial assistant"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.PRIORITY_SUPPORT,
  description: [{
    text: "Priority support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_REPORT,
  description: [{
    text: "In-depth credit report ($50 value)"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.REPORT_DISPUTE,
  description: [{
    text: "Dispute negative remarks"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.CREDIT_MONITORING,
  description: [{
    text: "Credit monitoring"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.ALL_BUREAU_SUPPORT,
  description: [{
    text: "All major bureau support"
  }]
}, {
  title: BOOST_SUBSCRIPTION_FEATURES.NO_HARD_PULL,
  description: [{
    text: "No hard credit pulls"
  }]
}];