"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.US_STATES = exports.LEAD_SOURCES = exports.BOOST_APP_URL_IOS = exports.BOOST_APP_URL_ANDROID = exports.BOOST_APP_SCHEME = exports.ANALYTICS_EVENTS = void 0;
/* LEAD */
var LEAD_SOURCES = exports.LEAD_SOURCES = {
  BOOST_LANDING: "BOOST_LANDING",
  BOOST_POPUP: "BOOST_POPUP",
  BOOST_PRICING: "BOOST_PRICING",
  CARD_LANDING: "CARD_LANDING",
  IQ_LANDING: "IQ_LANDING",
  PLATFORM_ONBOARDING: "PLATFORM_ONBOARDING",
  KOSMOFI_LANDING: "KOSMOFI_LANDING",
  CREDIT_MONITORING_DASHBOARD: "CREDIT_MONITORING_DASHBOARD",
  LANDING_BOOST_CHECKOUT: "LANDING_BOOST_CHECKOUT",
  DASHBOARD_BOOST_CHECKOUT: "DASHBOARD_BOOST_CHECKOUT",
  DASHBOARD_BOOST_UPGRADE_POPUP: "DASHBOARD_BOOST_UPGRADE_POPUP",
  DASHBOARD_BOOST_LOOKBACK: "dashboard_boost_lookback",
  DASHBOARD_BUSINESS_CC_BANNER: "dashboard_business_cc_banner"
};
var ANALYTICS_EVENTS = exports.ANALYTICS_EVENTS = {
  NEW_LEAD: "tomo_new_lead"
};
var BOOST_APP_SCHEME = exports.BOOST_APP_SCHEME = "tomo-boost://";
var BOOST_APP_URL_IOS = exports.BOOST_APP_URL_IOS = "https://apps.apple.com/app/6504301873";
var BOOST_APP_URL_ANDROID = exports.BOOST_APP_URL_ANDROID = "https://play.google.com/store/apps/details?id=com.tomocredit.boost";

// including DC
var US_STATES = exports.US_STATES = [{
  value: "AL",
  name: "Alabama"
}, {
  value: "AK",
  name: "Alaska"
}, {
  value: "AZ",
  name: "Arizona"
}, {
  value: "AR",
  name: "Arkansas"
}, {
  value: "CA",
  name: "California"
}, {
  value: "CO",
  name: "Colorado"
}, {
  value: "CT",
  name: "Connecticut"
}, {
  value: "DE",
  name: "Delaware"
}, {
  value: "DC",
  name: "District Of Columbia"
}, {
  value: "FL",
  name: "Florida"
}, {
  value: "GA",
  name: "Georgia"
}, {
  value: "HI",
  name: "Hawaii"
}, {
  value: "ID",
  name: "Idaho"
}, {
  value: "IL",
  name: "Illinois"
}, {
  value: "IN",
  name: "Indiana"
}, {
  value: "IA",
  name: "Iowa"
}, {
  value: "KS",
  name: "Kansas"
}, {
  value: "KY",
  name: "Kentucky"
}, {
  value: "LA",
  name: "Louisiana"
}, {
  value: "ME",
  name: "Maine"
}, {
  value: "MD",
  name: "Maryland"
}, {
  value: "MA",
  name: "Massachusetts"
}, {
  value: "MI",
  name: "Michigan"
}, {
  value: "MN",
  name: "Minnesota"
}, {
  value: "MS",
  name: "Mississippi"
}, {
  value: "MO",
  name: "Missouri"
}, {
  value: "MT",
  name: "Montana"
}, {
  value: "NE",
  name: "Nebraska"
}, {
  value: "NV",
  name: "Nevada"
}, {
  value: "NH",
  name: "New Hampshire"
}, {
  value: "NJ",
  name: "New Jersey"
}, {
  value: "NM",
  name: "New Mexico"
}, {
  value: "NY",
  name: "New York"
}, {
  value: "NC",
  name: "North Carolina"
}, {
  value: "ND",
  name: "North Dakota"
}, {
  value: "OH",
  name: "Ohio"
}, {
  value: "OK",
  name: "Oklahoma"
}, {
  value: "OR",
  name: "Oregon"
}, {
  value: "PA",
  name: "Pennsylvania"
}, {
  value: "RI",
  name: "Rhode Island"
}, {
  value: "SC",
  name: "South Carolina"
}, {
  value: "SD",
  name: "South Dakota"
}, {
  value: "TN",
  name: "Tennessee"
}, {
  value: "TX",
  name: "Texas"
}, {
  value: "UT",
  name: "Utah"
}, {
  value: "VT",
  name: "Vermont"
}, {
  value: "VA",
  name: "Virginia"
}, {
  value: "WA",
  name: "Washington"
}, {
  value: "WV",
  name: "West Virginia"
}, {
  value: "WI",
  name: "Wisconsin"
}, {
  value: "WY",
  name: "Wyoming"
}];